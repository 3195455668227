<style>
.suggest1 {  padding-bottom: 0 }
.cartel_view1 { overflow: auto; padding-bottom: 10px }
.swiper-wrpper1 { justify-content: flex-start }
.swiper-slice1 { margin-right: 10px; flex-basis: 80px }
</style>
<template>

	<div
		class="section_wrap pt-50 pb-80" ref="post_list_item_view"
	>
		<!-- 추천 카르텔 -->
		<div class="suggest suggest1">
			<div class="suggest_result">
				<div class="container">
					<div class="swiper cartel_view cartel_view1" >
						<template
							v-if="items_cartel_recomm.length > 0"
						>
							<div class="swiper-wrapper swiper-wrpper1">
								<div
									v-for="(item, index) in items_cartel_recomm"
									:key="'item_' + index"
									class="swiper-slide swiper-slice1"
								>
									<div class="cartel_item">
										<a @click="$emit('to', { name: 'mafia049', params: { idx: item.cartl_number, from: $route.name}})">
											<div class="picture">
												<img
													v-if="item.cartl_img_url"
													:src="$request.upload_url(item.cartl_img_url)"
												/>
												<img
													v-else
													:src="require('@/assets/image/@noimage.png')" :alt="$language.cartel.recommend_cartel"
												/>
											</div>
											<span class="name">{{  item.cartl_name }}</span>
										</a>
									</div>
								</div>
							</div>
						</template>
						<div
							v-else
							class="cartel_view_none"
						>
							<span class="cont_none">{{ $language.cartel.NoRecommendCartel }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- //추천 카르텔 -->
		<!-- 카르텔 썸네일 리스트 -->
		<div class="post_wrap">
			<div class="container">
				<template
					v-if="items_board.length > 0"
				>
					<div
						v-for="(item, index) in list_bbs"
						:key="'item_' + index"
						class="post_layout_view"
					>
						<div class="contents_card">
							<article class="post_list_item_view">
								<!-- 게시글 작성자 -->
								<div class="post_author">
									<div class="left">
										<a
											@click="on098(item)"
										>
											<span class="profile_inner">
												<img
													v-if="item.profle_nft_card_img_url || item.post_member_profle_nft_card_img_url"
													:src="$request.upload_url(item.profle_nft_card_img_url ? item.profle_nft_card_img_url : item.post_member_profle_nft_card_img_url)"
												/>
												<img
													v-else
													:src="require('@/assets/image/@profile_inner.png')" alt="No Picture"
												>
											</span>
											<strong class="name"><em v-if="item.is_block" class="state-block">{{ $language.common.cut_off }}</em>{{ item.nickname }}</strong>
										</a>
									</div>
									<div class="right">
										<span class="time">{{  item.time_t }}</span>
										<button
											@click="onMore(item, index)"
											class="post_set"><span class="hide">{{ $language.common.setting }}</span></button>
									</div>
								</div>

								<!-- //게시글 작성자 -->
								<!-- 게시글 메인 영역 -->
								<div
									v-if="!item.is_block"
									class="post_main_view mt-10"
								>
									<!-- 비주얼 영역 -->
									<div class="photo_view">
										<!-- 슬라이드 -->

										<div class="photo_collage_slide">

											<carousel
												:perPage="1"
											>
												<slide
													v-for="(file, f_index) in item.post_file_list"
													:key="'item_' + index + '_file_' + f_index"
													class="photo_collage_view flex-column justify-center"
												>
													<img
														v-if="file.appendix_file_div_code.indexOf('CA01000001') > -1"
														:src="$request.upload_url(file.file_url)"
														@click="toDetail(item, index)"
													/>
													<video
														v-if="file.appendix_file_div_code.indexOf('CA01000002') > -1"
														width="100%"
														controls
													>
														<source
															:src="file.file_url"
														>
													</video>
													<div
														v-if="file.appendix_file_div_code.indexOf('CA01000003') > -1"
														class="text_view" style="position: static !important; padding: 0 !important;"
													>
														<figure class="audio_wrap">
															<div class="audio_bg">
																<span class="hide">{{ $language.common.soundBackgroundImg }}</span>
															</div>
															<div class="bg-gray">
																<video
																	width="100%"
																	controls
																	height="40px"
																>
																	<source
																		:src="file.file_url"
																	>
																</video>
															</div>
														</figure>
													</div>
												</slide>
											</carousel>
										</div>
										<!-- 슬라이드 -->
									</div>
									<!-- //비주얼 영역 -->
									<!-- 텍스트 영역 -->
									<div
										v-if="item.vote_fg == 'Y'"
										class="text_view"
									>
										<button
											class="thumbnail_btn_s st_wjoin"

										>투표</button>
									</div>
									<div
										class="text_view"
										@click="toDetail(item)"
									>
										<span class="text_area">
										{{  item.contents ? item.contents : item.post_contents }}
										</span>
										<!-- TODO : 3줄 이상의 경우 더보기 버튼 생성  -->
										<span
											v-if="!item.is_block"
											class="text_more"
										>{{ $language.common.seeMore }}</span>
									</div>
									<!-- //텍스트 영역 -->
								</div>
								<!-- //게시글 메인 영역 -->
								<!-- 게시글 공감 댓글 -->
								<div class="post_count_view">
									<div class="post_count">
										<div class="postcountleft">
											{{ $language.common.sympathy }} <span>{{  item.sympaty_count }}</span>
										</div>
										<div class="postcountright">
											{{ $language.common.comment }} <span>{{  item.comment_count }}</span>
										</div>
									</div>
									<div class="post_add" style="position: relative">
										<div class="post_add_box">
											<div class="add_col">
												<!-- TODO : 공감 버튼을 누를경우 on 추가 -->
												<button
													@click="$set(item, 'is_emoji', !item.is_emoji)"
												><span class="add_status add_empathy " :class="{ on: item.is_empathy}">{{ $language.common.sympathy }}</span></button>
											</div>
											<div class="add_col">
												<a
													@click="toComment(item)"
												><span class="add_status add_comment">{{ $language.common.comment }}</span></a>
											</div>
										</div>

										<div
											v-if="item.is_emoji"
											class="emotion_view" style="top: -25px; left: 10px"
										>
											<div class="emotion_select_view">
												<div class="list" style="display: flex; padding: 5px">
													<!-- 선택된 표정의 버튼에는 on 클래스 추가 -->
													<button
														v-for="(emoji, index) in icons_emoji"
														:key="'emoji_icon_' + index"
														type="button" class="btn_emotionitem" data-emotion-type="best"
														:style="item.sympaty_icon_code == emoji.code ? 'background-color: #bbb; border-radius: 50px': ''"
														@click="postEmoji(item, emoji)"
													>
												<span
													class="faceicon_30  em_select_btn"
													:class="'f_' + emoji.type + '_30'"

												>{{  emoji.txt }}</span>
													</button>
												</div>
												<span class="list_arrow"></span>
											</div>
										</div>
									</div>
								</div>
								<!-- //게시글 공감 댓글 -->
							</article>
						</div>
					</div>
				</template>
				<div
					v-else
					class="list_none"
					style="overflow: hidden; height: 100%;"
					:style="$route.params.code ? 'padding-top: 100%' : ''"
				>
					<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
					<span>{{ $language.common.NotFoundArticle }}</span>
				</div>
			</div>
		</div>
		<!-- //카르텔 썸네일 리스트 -->
		<div
			v-if="is_create"
			class="cartel_open"
		>
			<button
				@click="$emit('to', { name: 'mafia119'})"
			>
				<div class="cartel_open_btn">
					<span>{{ $language.common.cartel }}<br><em>{{ $language.common.establish }}</em></span>
				</div>
			</button>
		</div>

		<!-- 회원 정보 -->
		<mafia098
			v-if="is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_profile"

			@cancel="off098"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color: white"
		></mafia098>

		<!-- 더보기 -->
		<mafia064
			v-if="is_more"

			:user="user"
			:item_bbs="item_more"

			@cancel="is_more = false"
			@deleteBbs="deleteBbs"
			@move="moveBbs"
			@modify="onModify"
			@block="doBlock"
			style="z-index: 1003"
		>
		</mafia064>

		<!-- 게시물 상세 조회 -->
		<cartel_bbs_detail
			v-if="is_item_detail"

			:user="user"
			:cartel="item_cartel"
			:board="item_board"
			:bbs="item_more"
			:is_item_detail_comment="is_item_detail_comment"

			@cancel="update"
			@toModify="onModify"
			@deleteBbs="deleteBbs"
			class="layer-popup"
		></cartel_bbs_detail>

		<ScrollPagination
			v-if="$refs.post_list_item_view"
			:body="$refs.post_list_item_view"
			:item_search="item_search.post"
			:max="max"
			:is_add="is_add"
			:top="$refs.cartel_cover"

			@change="getData"
		></ScrollPagination>
	</div>
</template>
<script>

import { Carousel, Slide } from 'vue-carousel';
import mafia064 from "@/view/Cartel/mafia064";
import mafia098 from "@/view/Cartel/mafia098";
import cartel_bbs_detail from "@/view/Cartel/cartel_bbs_detail"
import ScrollPagination from "@/components/ScrollPagination";

export default {
	name: 'mafia044'
	, props: ['user']
	, components: {ScrollPagination, Carousel, Slide, mafia064, cartel_bbs_detail, mafia098}
	, data: function(){
		return {
			program: {
				name: 'cartel'
				, title: this.$language.common.cartel + ' ' + this.$language.common.list
				, type: 'cartel'
				, not_footer: false
				, not_header: false
			}
			, items_cartel: []
			, items_cartel_recomm: []
			, cartl_open_possible_fg: ''
			, item_search: {
				page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
				, post: {
					page_number: this.$language.base.page_number
					, pagerecnum: this.$language.base.pagerecnum

				}
			}
			, items_board: []
			, is_more: false
			, item_more: {}
			, is_item_detail: false
			, item_cartel: {}
			, item_board: {}
			, is_item_detail_comment: false
			, icons_emoji: [

				{ code: 'CA01100001', type: 'best', txt: this.$language.emoticon.best}
				, { code: 'CA01100002', type: 'funny', txt: this.$language.emoticon.funny}
				, { code: 'CA01100003', type: 'like', txt: this.$language.emoticon.like}
				, { code: 'CA01100004', type: 'sad', txt: this.$language.emoticon.sad}
				, { code: 'CA01100005', type: 'surprise', txt: this.$language.emoticon.surprise}
				, { code: 'CA01100006', type: 'angry', txt: this.$language.emoticon.angry}
			]
			, item_profile: {}
			, is_098: false
			, is_add: true
			, max: false
		}
	}
	, computed: {
		is_create: function(){
			let t = false

			if(this.cartl_open_possible_fg == 'Y'){
				t = true
			}

			return t
		}
		, list_my_cartel: function(){

			return this.items_cartel.filter(function(item){
				if(item.status == 0){
					item.is_confirm = false
				}else{
					item.is_confirm = true
				}
				return item
			})
		}
		, list_bbs: function(){

			return this.items_board.filter((item) => {
				if(item.blok_member_fg == 'Y'){
					item.is_block = true
				}else{
					item.is_block = false
				}
				if(item.sympaty_icon_code != ''){
					item.is_empathy = true
				}
				item.time_t = this.$date.getTimeStory(item.registdate)
				return item
			})
		}
	}

	, methods: {
		getData: function(page){
			if(this.user.member_number){
				this.getMemberData(page)
			}else{
				this.getOpenData(page)
			}
		}
		, getOpenData: async function(page){
			try{
				this.is_add = false
				this.$bus.$emit('on', true)
				this.$set(this.item_search.post, 'page_number', page)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_open_cartel_recomm_list
					, data: {
						page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_cartel_recomm = result.data.recom_cartl_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_add = true
			}
		}
		, getMemberData: async function(page){
			try{
				this.is_add = false
				this.$bus.$emit('on', true)
				this.$set(this.item_search.post, 'page_number', page)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_main_info
					, data: {
						member_number: this.user.member_number
						, cartl_list_page: {
							page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, nrt_post_list_page: {
							page_number: this.item_search.post.page_number
							, pagerecnum: this.item_search.post.pagerecnum
						}
					}
					, type: true
				})

				if(result.success){
					this.items_cartel = result.data.member_cartl_list
					this.items_cartel_recomm = result.data.recom_cartl_list

					this.cartl_open_possible_fg = result.data.cartl_open_possible_fg
					if(result.data.entry_cartl_nrt_post_list.length > 0){
						if(this.items_board.length > 0){
							this.items_board = this.items_board.concat(result.data.entry_cartl_nrt_post_list)
						}else{
							this.items_board = result.data.entry_cartl_nrt_post_list
						}
						this.max = false
					}else{
						this.max = true
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_add = true
			}
		}
		, onMore: function(item, index){
			this.is_more = true
			this.item_more = item
			this.item_more.index = index
		}
		, deleteBbs: async function(){
			this.is_more = false
			this.is_item_detail = false
			this.items_board.splice(this.item_more.index, 1)
		}
		, moveBbs: async function(){
			this.is_more = false
			this.is_delete = false
			await this.mount()
		}
		, onModify: function(){
			this.is_item_modify = true
		}
		, modify: function(item){
			this.is_more = false
			this.is_item_modify = false
			console.log('modify', item)
			// this.getBbs(item)
			if(this.is_item_detail){
				this.is_item_detail = false
				setTimeout(() => {
					this.is_item_detail = true
				}, 5)
			}
		}
		, doBlock: function(type){

			this.items_board = this.items_board.filter((item) => {
				if(item.post_member_number == this.item_more.post_member_number){
					item.blok_member_fg = type ? 'Y' : 'N'
				}
				return item
			})
		}
		, toDetail: function(item, index){

			if(!item.is_block){

				this.item_more = item
				this.item_more.index = index

				// this.is_item_detail = true
				this.is_item_detail_comment = false

				console.log(item, index)

				let path = this.$route.path.split('#')
				console.log('toDetail', this.$route.path, path)
				if(path[1] == 'detail'){
					this.$router.push(path[0])
				}else{
					this.$router.push(this.$route.path + '#detail')
				}
			}
			// document.location.href = '#detail'

			// this.$bus.$emit('to', { name: 'mafia058', params: { idx: this.$route.params.idx, code: item.board_type_code ? item.board_type_code : this.$route.params.code, b_id: item.board_number, bbs_id: item.bulletin_number, from: 'mafia049'}})
		}
		, toComment: function(item, index){
			if(!item.is_block){
				this.item_more = item
				this.item_more.index = index

				// this.is_item_detail = true
				// this.is_item_detail_comment = true

				this.$router.push(this.$route.path + '#comment')
				// this.$bus.$emit('to', { name: 'mafiaCommentList', params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, bbs_id: item.bulletin_number}})
			}
		}

		, update: function(item){

			this.item_more.sympaty_icon_code = item.sympaty_icon_code
			this.is_item_detail = false
			// this.getBbs(this.item_more)
			let path = this.$route.path.split('#')
			this.$router.push(path[0])
		}

		, postEmoji: async function(item, emoji){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_emoji
					, data: {
						member_number: this.user.member_number
						, cartl_number: item.cartl_number
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
						, sympaty_icon_code: emoji.code
					}
					, type: true
				})

				if(result.success){
					item.is_empathy = emoji.code
					item.sympaty_icon_code = emoji.code
					item.is_emoji = false
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_emoji = false
				this.$bus.$emit('on', false)
			}
		}
		, on098: function(item){
			this.is_098 = true
			this.item_profile = item
		}
		, off098: function(){
			this.is_098 = false
			this.$bus.$emit('onLoad', this.program)
		}
	}
	, async created() {
		// console.log('044 user', this.user)
		this.$emit('onLoad', this.program)
		await this.getData(1)

		if(await this.$common.getBrowser() === 'Safari'){
			document.body.scrollTop = 0
		}
	}
	, watch:{
		$route(to, from){
			console.log(to, from)
			this.is_item_detail = false
			this.is_item_detail_comment = false
			if(to.hash){
				switch (to.hash){
					case '#detail':
						this.is_item_detail = true
						break
					case '#comment':
						this.is_item_detail = true
						this.is_item_detail_comment = true
						break
				}
			}else{
				this.$emit('onLoad', this.program)
			}
		}
	}
}
</script>